import React from 'react'
import share from '../../assets/Icon/share.svg'
import './Header.css'
const Header = ({data}) => {


    const shareActivity = () => {
        const shareUrl = `http://${data?.subdomain}.myplotpic.com`    
        const shareTitle = "Check out this kids' activity I found on Wondrfly:";
        const shareText = "Check out this kids' activity I found on Wondrfly:";
    
        if (navigator.share) {
          // Use Web Share API if supported
          navigator
            .share({
              title: shareTitle,
              text: shareText,
              url: shareUrl,
            })
            .then(() => console.log('Successfully shared'))
            .catch((error) => console.error('Error sharing:', error));
        } else {
          // Fallback for unsupported browsers
          alert(`Copy this link to share: ${shareUrl}`);
        }
     
      };
  return (
    <div className='header_section'>
        
        <div className='left_side'>
            
            <div className='main_div'>
                <h1>{data?.name ||'East Heaven Raise, Visakhapatnam'}</h1>
                <p>{data?.address ||'Old Gajuwaka, Visakhapatnam, Andhra Pradesh, India, 530026'}</p>
            </div>
        </div>
        <div className='right_side'>
            
            <div onClick={shareActivity} style={{cursor:'pointer'}}>
                <img src={share} alt="share.png" />
            </div>
        </div> 
        
        </div>
  )
}

export default Header