import React from 'react'
import './project.css'

const ProjectsFeatures = ({data}) => {
  const colorClasses = ["bg-info", "bg-success", "bg-light", "bg-warning"];

  return (
    <div>
        
        <div class="min-vh-100 bg-gradient p-4">
  <div class="container">
    <div class="text-center text-white mb-4 upper-div">
      <h2 class="fw-semibold">Projects Features</h2>
      <p class="fs-5">Total Extent: {data?.features_Totle_area || '200 Acres'}</p>
    </div>

    <div class="row g-5">
      
    {data?.Features.map((i, key) => (
  <div key={key} className="col-12 col-md-6 col-lg-4 px-0">
    <div className="px-3">
      <div className="card bg-transparent backdrop-blur mx-auto rounded-4 p-4">
        <div className="mb-3 d-flex justify-content-between align-items-center inner-div">
          <h3 className="fs-5 fw-medium">{i?.title}</h3>
          <span>{i?.percentvalue}%</span>
        </div>
        <div className="row g-3">
          {i?.detailfeatures.map((item, keyindex) => (
            <div className="col-6" key={keyindex}>
              <div className={`${colorClasses[(key * 2 + keyindex) % colorClasses.length]} inner-sub-div bg-opacity-50 rounded-3 p-3 text-center`}>
                <div className="fs-4 fw-bold text-dark">{item?.value}</div>
                <div className="small text-muted">{item?.subtitle}</div>
                <div className="small text-secondary mt-1">{item?.unit_value}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
))}
      
      
    </div>
  </div>
</div>


    </div>
  )
}

export default ProjectsFeatures