import { Play } from 'lucide-react';
import './Style.css'
import img from './image 6.png'
 
export default function VideoGallery({data}) {

  const getSafeEmbedUrl = (url) => {
    if (url) {
      
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      const match = url.match(regExp);
      return match && match[2].length === 11
      ? `https://www.youtube.com/embed/${match[2]}`
      : url;
    }
  };
  const formatDate = (isoDate) => {
    try {
      const date = new Date(isoDate);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (error) {
      return 'Invalid Date';
    }
  };
  return (
    <div className="min-vh-100 bg-last-sec py-5">
      <div className="container">
        {/* Header Section */}
        

        {/* Main Video Section */}
        <div className='d-flex flex-wrap justify-content-center'>
        <div className="text-center heading mt-5 ratio-16x9">
          <h1 className="display-5 fw-bold">Explore Current Status of Project</h1>
          <p className="text-muted">
            Uploaded Date: {data?.updatedAt ? formatDate(data?.updatedAt) : 'Unknown'}
          </p>
        </div>
        <div className="card mb-5 ratio-16x9  shadow-sm">
          <div className="card-body p-0">
            <div className="ratio bg-dark position-relative d-flex align-items-center justify-content-center">
              <div className="rounded-circle bg-light bg-opacity-25 d-flex align-items-center justify-content-center" 
                   style={{ width: '80px', height: '80px', transition: 'transform 0.3s' }}>
                <Play className="text-white fs-1" />
              </div>

              
              <iframe width="853" height="480" src={getSafeEmbedUrl(data?.ytlink[0]?.link)} title="Who’s Choking Delhi? 🚨 Stubble Burning, Factories&#39; Pollution? The Smog Truth Revealed!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              {/* <iframe width="853" height="480" src="" title="Adani | ₹2000 Crore Bribery Case EXPOSED | Dhruv Rathee" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
            </div>
          </div>
        </div>
                       </div>

        {/* Video Gallery */}
        <div className="row g-4">
          {data?.ytlink.slice(0,5).map((index) => (
            <div className="col-md-4 d-flex justify-content-center" key={index}>
              <div className="card small-video-card shadow-sm">
                <div className="card-body p-0 ">
                  <div className="ratio small-video-card bg-dark position-relative d-flex align-items-center justify-content-center">
                  <iframe width="853" height="480" src={getSafeEmbedUrl(index?.link)} title="Who’s Choking Delhi? 🚨 Stubble Burning, Factories&#39; Pollution? The Smog Truth Revealed!" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                   
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Footer Section
        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center bg-primary text-white rounded p-3 mt-5">
          <div className="fw-semibold">Developer: Sukruthi Developers</div>
          <div className="fw-semibold">Status: Ongoing Plots available</div>
        </div> */}
      </div>
    </div>
  );
}
