import { FileIcon } from "lucide-react";
import './Style.css'
import imgoffr from '../../assets/Group 273.png'
import pdf from '../../assets/Icon/file-1 1.svg'
import pdf2 from '../../assets/Icon/file-2 1.svg'
import AP_RERA from '../../assets/AP_RERA.png'
import VMRDA from '../../assets/VMRDA.png'
import { baseUrl } from "../../../api/api";
export default function PropertyDocuments({data}) {
  return (
    <div className=" d-flex flex-wrap p-30 justify-content-center">

    <div className=" px-0   border-none mx-auto col-md-12 col-lg-9 "  >
      {/* Documents Section */}
      <section>
        <h2 className="h5 font-weight-bold doc_heading mb-4">Documents</h2>
        <div className="row g-4">
          {data?.documentSchema.map((doc) => (
            <div key={doc.title} className="col-6 col-sm-3">
              <div className="card  doc-item">
                <div className="card-body d-flex align-items-center gap-2 p-0 justify-content-center">
                  <div
                    className={`d-flex justify-content-center align-items-center rounded-circle `}
                    style={{ width: "40px", height: "40px" }}
                  >
                    <img src={pdf} alt={doc.name} />
                  </div>
                 <a href={`${baseUrl}${doc?.file}`} target="_blank"> <span className="" style={{fontSize:'20px',color:'black'}}>{doc.name}</span></a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Approvals Section */}
      <section className="mt-5">
        <h2 className="h5 font-weight-bold doc_heading mb-4">Approvals</h2>
        <div className="row g-4">
          {/* VMRDA Card */}
         {data?.approvalSchema.map((i,index)=>(

        <div key={index} className="col-12 col-sm-12 col-md-5">
            <div className="card brand_card order-0 shadow-sm">
              <div className="card-body row align-items-center ">
                <div className="col-5"  >
                  <img
                    src={`${baseUrl}${i?.logo}`}
                    alt={i?.authority}
                    className="img-fluid"
                    width='100%'
                    style={{
                      borderRadius:'5px'
                    }}

                  />
                </div>
                <div className="col-7">
                  <h3 className="h6 font-weight-bold mb-1">{i?.authority}</h3>
                  <p className="text-muted small mb-0">Approval No: {i?.approvalNo}</p>
                  <p className="text-muted small">
                    {i?.description}
                  </p>
                </div>
              </div>
            </div>
          </div>  ))}

         
        </div>
      </section>
    </div>


    <div className="col-lg-3 col-md-12">
<div className="text-center price_offer">

  <div className="heading">
   <h2>
    
   Price  Starts From
    </h2>
  </div>
  <div className="pricing-div">
<div className="inner-div">
<h1>
₹{data?.priceDetail1}
</h1>
<p>
Sq. Yard
</p>
</div>

  </div>
<h4 className="last_price">
  Card value : <span>₹{data?.priceDetail2}</span>
</h4>
    {/* <img src={imgoffr} alt="" /> */}
</div>
    </div>
    </div>

  );
}
