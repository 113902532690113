import React from 'react'
import './Slider.css'
import img from '../../assets/industrial-Plots-Yamuna 1.png'
import MapComponent from '../map/Map'
import { baseUrl } from '../../../api/api'
const Silder = ({data}) => {
  return (
    <div className='slider_main_container'>
        <div className='slider_container'> 
<div className="slider_div">

<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-indicators">
    {data?.project_progress.map((i, key) => (
      <button
        key={key}
        type="button"
        data-bs-target="#carouselExampleCaptions"
        data-bs-slide-to={key}
        className={key === 0 ? 'active' : ''}
        aria-label={`Slide ${key + 1}`}
      ></button>
    ))}
  </div>

  <div class="carousel-inner">
    {data?.project_progress.length>0 ? data?.project_progress.map((i, key) => (
      <div
        key={key}
        className={`carousel-item ${key === 0 ? 'active' : ''}`}
      >
        <img
          src={`${baseUrl}${i.image}`}
          className="d-block w-100 h-100"
          alt="..."
        />
      </div>
    )):'No image Found'}
  </div>

  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

</div>

<div className="map_main_div">
   
   <div className='map_div'>
  < MapComponent data={data}/>

  <div className='detail_location'>
    <p>
        <span>Address:</span> {data?.address || 'Not available'}
    </p>
    <p>
        <span>State: </span>
        {data?.State === null || data?.State === undefined ? 'Not available' : data?.State}
    </p>
    <p>
        <span>Pincode:</span>
        {data?.pincode === null || data?.pincode === undefined ? 'Not available' : data?.pincode}
    </p>
    <p>
        <span>Country:</span>
        {data?.country === null || data?.country === undefined ? 'India' : data?.country}
    </p>
</div>

   </div>
</div>
        </div>

    </div>
  )
}

export default Silder