import React, { useState } from 'react'
import { Mail, Phone } from "lucide-react";
import './Style.css'
import VerificationForm from './Verify';
import { baseUrl } from '../../../api/api';
const BookingPlot = ({data}) => {


  const [formValues, setFormValues] = useState({
    name: "",
    plotNumber: "",
    amount: "",
    phone: "",
  });

  // State to toggle modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormValues((prevValues) => ({ ...prevValues, [id]: value }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Mock OTP API call
    try {
     
      // Replace with your OTP API integration
      const response = await fetch(`${baseUrl}api/sendOTPtoContectNumberWOLogin`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ contectNumber: formValues.phone }),
      });

      const res = await response.json(); // Parse the JSON response
      if (res.success) {
        const otp = res.data.OTP; // Extract OTP
  
        // Convert OTP to binary and save to localStorage
        const binaryOTP = otp.toString(2);
        localStorage.setItem("otpBinary", binaryOTP);
  
        console.log("OTP Sent Successfully!");
        setIsModalOpen(true); // Open the verification modal
      } else {
        console.error("Failed to send OTP.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };
  return (
    <section className=" position-relative min-vh-453 overflow-hidden">
    {/* Background with gradient overlay */}
    <div
      className="bg-gradient position-absolute w-100 h-100"
      
    ></div>

    <div className="container position-relative py-5">
      <div className="row align-items-center">
        {/* Left Column - Content */}
        <div className="col-md-12 col-lg-6 d-flex justify-content-center text-white mb-4 mb-lg-0">
            <div className='text-div'>

          <h1 className="display-4 fw-bold " style={{marginBottom:'16px'}}>Book Your Plot</h1>
          <p className="lead opacity-75 mb-4">
          Create a lasting legacy with this premium plot. A wise investment for generations to come.
          </p>
          <div className="d-flex flex-column flex-sm-row gap-3">
            <div className="d-flex align-items-center gap-2">
              <Phone className="icon-sm" />
              <span>{data?.phone}</span>
            </div>
            <div className="d-flex align-items-center gap-2">
              <Mail className="icon-sm" />
              <span>{data?.email ||'abc@gmail.com'}</span>
            </div>
          </div>
          </div>
          </div>

        {/* Right Column - Form */}
        <div className="col-md-12 col-lg-6 d-flex justify-content-center">
          <div className="card form-card shadow-lg">
            <div className="card-body p-4">
             
              <form>
                <div className="" style={{marginBottom:'16px'}}>
                  
                  <input type="text" id="name" className="form-control" placeholder="Name" required onChange={handleChange} />
                </div>
                <div className="" style={{marginBottom:'16px'}}>
                   
                  <input type="text" id="plotNumber" className="form-control" placeholder="Plot number" required onChange={handleChange} />
                </div>
                {/* <div className="" style={{marginBottom:'16px'}}>
                  
                  <input type="text" id="amount" className="form-control" placeholder="Amount" required onChange={handleChange} />
                </div> */}
                <div className="" style={{marginBottom:'16px'}}>
                 
                  <input type="tel" id="phone" className="form-control" placeholder="Phone Number" required onChange={handleChange} />
                  <small className="form-text text-muted">
                    OTP will be sent to the above Phone number you entered.
                  </small>
                </div>
                <button
                  type="button" // Change type to button to avoid default form submission
                  data-bs-toggle="modal"
                  data-bs-target="#verificationModal"
                  onClick={handleSubmit}
                  className="btn btn-warning w-100 text-uppercase fw-bold"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
 <VerificationForm {...formValues} resend={handleSubmit} />
  </section>
  )
}

export default BookingPlot