import React from 'react'
import layout from '../../assets/Layout.png'
import uni from '../../assets/Icon/uni.svg'
import school from '../../assets/Icon/school.svg'
import GroCenter from '../../assets/Icon/GroCenter.svg'
import Barbell from '../../assets/Icon/Barbell.svg'
import ParkBench from '../../assets/Icon/Park Bench.svg'
import HospitalSign from '../../assets/Icon/Hospital Sign.svg'
import './Layout_section.css'
import { baseUrl } from '../../../api/api'
const Layout_Section = ({data}) => {

    const color= [
        {
            icon:uni,
            bg:'',
            bgdark:"",

        },
        {
            icon:school,
            bg:'bg-bule',
            bgdark:"bg-bule-dark"
        },
        {
            icon:GroCenter,
            bg:'bg-prpul',
            bgdark:"bg-prpul-dark"
        },
        {
            icon:Barbell,
            bg:'bg-Lilac',
            bgdark:"bg-Lilac-dark"
        },
        {
            icon:ParkBench,
            bg:'bg-red',
            bgdark:"bg-red-dark"
        },
        {
            icon:HospitalSign,
            bg:'bg-pink',
            bgdark:"bg-pink-dark"
        },
        {
            icon:uni,
            bg:'',
            bgdark:"",

        },
        {
            icon:school,
            bg:'bg-bule',
            bgdark:"bg-bule-dark"
        },
        {
            icon:GroCenter,
            bg:'bg-prpul',
            bgdark:"bg-prpul-dark"
        },
        {
            icon:Barbell,
            bg:'bg-Lilac',
            bgdark:"bg-Lilac-dark"
        },
        {
            icon:ParkBench,
            bg:'bg-red',
            bgdark:"bg-red-dark"
        },
        {
            icon:HospitalSign,
            bg:'bg-pink',
            bgdark:"bg-pink-dark"
        },
        {
            icon:uni,
            bg:'',
            bgdark:"",

        },
        {
            icon:school,
            bg:'bg-bule',
            bgdark:"bg-bule-dark"
        },
        {
            icon:GroCenter,
            bg:'bg-prpul',
            bgdark:"bg-prpul-dark"
        },
        {
            icon:Barbell,
            bg:'bg-Lilac',
            bgdark:"bg-Lilac-dark"
        },
        {
            icon:ParkBench,
            bg:'bg-red',
            bgdark:"bg-red-dark"
        },
        {
            icon:HospitalSign,
            bg:'bg-pink',
            bgdark:"bg-pink-dark"
        }
    
    ]
  return (
    <div className='layout-containter'>
        <div className='layout-main-section row'>
            <div className='left-section col-sm-12 col-md-6 col-lg-8'>
                <div className='sub-sec'>
                    
                    <div>
                        
                        <h2>Layout Plan</h2>


                        <div id="carouselExample" className="carousel slide">
  <div className="carousel-inner">
    {data?.layoutImage.map((item, index) => (
      <div
        key={index}
        className={`image-div carousel-item ${index === 0 ? "active" : ""}`}
      >
        <img
          src={`${baseUrl}${item}`}
          alt="Not available layout image"
          width="100%"
          height="100%"
        />
      </div>
    ))}
  </div>

  <button
    className="carousel-control-prev"
    type="button"
    data-bs-target="#carouselExample"
    data-bs-slide="prev"
  >
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button
    className="carousel-control-next"
    type="button"
    data-bs-target="#carouselExample"
    data-bs-slide="next"
  >
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>


                        
                    </div>
                </div>
            </div>
            <div className='right-section col-sm-12 col-md-6 col-lg-4'>
                <div>
                    <h5>What’s nearby?</h5>
                    <p>Explore nearby amenities to precisely locate your property 
and identify surrounding conveniences, with living environment
</p>

{data?.Highlights.map((i,key)=>( <div key={key} className={`item-div ${color[key].bg}`}>
<div className={`img-icon ${color[key].bgdark}`}>
    <img   src={baseUrl+i.icon} alt="" width="100%"
            />
</div>
<div className='tab-item'>
    <p className='mb-0'><span>{i?.title} : </span>{i?.distance}</p>
</div>


</div>))}


 
                </div>
            </div>

        </div>

    </div>
  )
}

export default Layout_Section